import React, { useState } from "react";
import NavBar from "./components/Navigation/navigation";
import { AppProvider } from "./components/useContext/useContext";
import HistoryPlaying from "./components/History";
import Auth from "./components/UserAuthen/UserAuthen";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ListEvent from "./components/ListEvent";
import Event from "./components/Main";

function App() {
  let [totalTurn, setTotalTurn] = useState(0);
  let [currentPoint, setCurrentPoint] = useState(null);
  let [playingStyle, setPlayigStyle] = useState("");
  let [aToken, setAToken] = useState("");

  const initData = {
    totalTurn: totalTurn,
    setTotalTurn: setTotalTurn,
    current_point: currentPoint,
    setCurrentPoint: setCurrentPoint,
    playing_type: playingStyle,
    setPlayigStyle: setPlayigStyle,
    setAToken: setAToken,
    aToken: aToken,
  };

  return (
    <AppProvider value={initData}>
      <div className="App">
        <NavBar />
        <BrowserRouter>
          <Routes>
            <Route path="/auth" element={<Auth />}>
              <Route path=":user_id" element={<Auth />} />
            </Route>
            <Route path="/" element={<ListEvent />} />
            <Route path="/mini_events/:id" element={<Event />} />
            <Route path="/history-playing" element={<HistoryPlaying />} />
          </Routes>
        </BrowserRouter>
      </div>
    </AppProvider>
  );
}

export default App;
