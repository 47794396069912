
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getListEvents } from "../../apis/index";
import Card from "react-bootstrap/Card";
import moment from "moment";
import ModalCode from "../Modal/modalCode";

function ListEvent() {
  let navigate = useNavigate();
  let [data, setData] = useState([]);
  let [openInputCode, setOpenInputCode] = useState(false);
  let [inputCode, setInputCode] = useState("");
  let [keyCode, setKeyCode] = useState("");
  let [errorMsg, setErrorMsg] = useState("");
  let [eventId, setEventId] = useState(null);

  const checkEventTime = (item) => {
    if (new Date().getTime() >= new Date(item.start_date).getTime()) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getListEvents().then((data) => {
      let newData = data.data
        .map((item) => {
          if (item.group_name === "lucky_wheel") {
            return item;
          } else {
            return null;
          }
        })
        .filter((item) => item);
      setData(newData);
      checkEventTime(newData);
    });
  }, []);
  useEffect(() => {
    if (data.length === 1 && !data[0].code_status) {
      navigate(`/mini_events/${data[0].id}`, {
        state: {
          data: data,
        },
      });
    }
  }, [data]);

  const handleShowModalInputCode = () => setOpenInputCode(true);
  const playButton = ({ id, event_code, code_status }) => {
    const handlePlayButtonClick = () => {
      handleShowModalInputCode();
      setKeyCode(event_code);
      setEventId(id);
    };
    return (
      <>
        {code_status ? (
          <div onClick={() => handlePlayButtonClick()}>
            <button className="btn-spin">Chơi ngay</button>
          </div>
        ) : (
          <Link to={`/mini_events/${id}`}>
            <button className="btn-spin">Chơi ngay</button>
          </Link>
        )}
      </>
    );
  };

  const submitCode = (code) => {
    if (code?.toUpperCase() !== keyCode?.toUpperCase()) setErrorMsg("Mã tham gia sự kiện không đúng");
    else {
      navigate(`/mini_events/${eventId}`, {
        state: {
          data: { code: keyCode, code_status: true },
        },
      });
    }
  };

  return (
    <div className="container">
      <div className="cont-wrap-main w-cntn scroll-y">
        <h2 className="ml-3 text-uppercase fw-bold mb-1 text-center genos-regular clr-primary">
          Danh sách sự kiện
        </h2>
        <div className="row event-lst text-center d-flex justify-content-center">
          {data.length > 0 ? (
            data.map((item) => {
              return (
                <div
                  className="col-sm-4 col-xs-12 col-md-4 col-lg-4"
                  key={item.id}
                >
                  <Card>
                    <Card.Body>
                      <Card.Title>{item.event_name}</Card.Title>
                      <Card.Text className="mb-0">
                        Bắt đầu:{" "}
                        {moment(item.start_date).format("DD-MM-YYYY HH:mm")}
                      </Card.Text>
                      <Card.Text className="mb-0">
                        Kết thúc:{" "}
                        {moment(item.end_date).format("DD-MM-YYYY HH:mm")}
                      </Card.Text>
                      {checkEventTime(item) ? (
                        playButton(item)
                      ) : (
                        <button className="btn-spin" disabled>
                          Chơi ngay
                        </button>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              );
            })
          ) : (
            <div className="mt-5 font-weight-bold fs-3 font-italic text-secondary">
              Không có sự kiện diễn ra
            </div>
          )}
        </div>
      </div>

      <ModalCode
        open={openInputCode}
        setOpen={setOpenInputCode}
        code={inputCode}
        setCode={setInputCode}
        onSubmit={submitCode}
        error={errorMsg}
        setError={setErrorMsg}
      />
    </div>
  );
}
export default ListEvent;
